/**
 * 操作日志表模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const operationLog = {
    // 操作日志表查询
    queryOperationLog(data) {
        return request({
            url: '/api/system/operationLog/queryOperationLog',
            method: 'post',
            data: data
        });
    },
    
}

export default operationLog;