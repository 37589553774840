<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="操作时间">
        <el-date-picker
            v-model="queryForm.opt"
            placeholder="操作时间"
            size="small"
            clearable
            value-format="YYYY-MM-DD"
            @change="clearQueryInput"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="操作人">
        <el-input
            v-model="queryForm.operator"
            placeholder="操作人"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="功能模块">
        <el-input
            v-model="queryForm.title"
            placeholder="功能模块"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="业务类型">
        <el-input
            v-model="queryForm.businessType"
            placeholder="业务类型"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="访问IP">
        <el-input
            v-model="queryForm.ip"
            placeholder="访问IP"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <!--          <el-form-item label="访问地点">
                  <el-input
                          v-model="queryForm.ipLocation"
                          placeholder="访问地点"
                          size="small"
                          clearable
                          @clear="clearQueryInput"
                  ></el-input>
                </el-form-item>-->
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="opt" label="操作时间" width="160"/>
      <el-table-column prop="operator" label="操作人">
        <template #default="scope">
          <span v-if="scope.row.businessType=='退出登录'">{{JSON.parse(scope.row.responseData).data}}</span>
          <span v-else>{{scope.row.operator}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="功能模块"/>
      <el-table-column prop="businessType" label="业务类型"/>
      <!--      <el-table-column prop="method" label="方法名称" show-overflow-tooltip="true"/>-->
      <!--      <el-table-column prop="requestMethod" label="请求方式"/>-->
      <!--      <el-table-column prop="url" label="请求URL" show-overflow-tooltip="true"/>-->
      <el-table-column prop="ip" label="访问IP"/>
      <el-table-column prop="ipLocation" label="访问地点" show-overflow-tooltip="true"/>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.businessType=='登录'&&scope.row.status==0&&JSON.parse(scope.row.responseData).code==1" size="mini">登录成功</el-tag>
          <el-tag type="error" v-if="scope.row.businessType=='登录'&&scope.row.status==0&&JSON.parse(scope.row.responseData).code==0" size="mini">登录失败</el-tag>

          <el-tag type="success" v-if="scope.row.businessType!='登录'&&scope.row.status==0" size="mini">成功</el-tag>
          <el-tag type="error" v-if="scope.row.businessType!='登录'&&scope.row.status==1" size="mini">失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-link type="primary" icon="el-icon-view" size="mini" @click="detail(scope.$index, scope.row)">详细</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--操作日志表新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="50%"
      @close="handleClose"
  >
    <!--   非登录的显示   -->
    <div v-if="ruleForm.businessType!='登录'">
      <el-descriptions column="2">
        <el-descriptions-item label="操作时间：">{{ ruleForm.opt }}</el-descriptions-item>
        <el-descriptions-item label="操作人：">{{ ruleForm.operator }}</el-descriptions-item>
        <el-descriptions-item label="功能模块：">{{ ruleForm.title }}</el-descriptions-item>
        <el-descriptions-item label="业务类型：">{{ ruleForm.businessType }}</el-descriptions-item>
        <el-descriptions-item label="方法名称：">{{ ruleForm.method }}</el-descriptions-item>
        <el-descriptions-item label="请求方式：">{{ ruleForm.requestMethod }}</el-descriptions-item>
        <el-descriptions-item label="请求URL：">{{ ruleForm.url }}</el-descriptions-item>
        <el-descriptions-item label="访问IP：">{{ ruleForm.ip }}</el-descriptions-item>
        <el-descriptions-item label="访问地点：">{{ ruleForm.ipLocation }}</el-descriptions-item>
        <el-descriptions-item label="状态：">
          <el-tag type="success" v-if="ruleForm.status==0" size="mini">成功</el-tag>
          <el-tag type="error" v-if="ruleForm.status==1" size="mini">异常</el-tag>
        </el-descriptions-item>
        <el-descriptions-item span="2" label="请求参数：">{{ ruleForm.requestParam }}</el-descriptions-item>
        <el-descriptions-item span="2" label="响应数据：" v-if="ruleForm.status==0">{{ ruleForm.responseData }}</el-descriptions-item>
        <el-descriptions-item span="2" label="异常信息：" v-if="ruleForm.status==1">{{ ruleForm.errorMsg }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!--  登录日志  -->
    <div v-if="ruleForm.businessType=='登录'">
      <el-descriptions column="2">
        <el-descriptions-item label="登录时间：">{{ ruleForm.opt }}</el-descriptions-item>
        <el-descriptions-item label="状态：">

          <el-tag type="success" v-if="ruleForm.status==0&&JSON.parse(ruleForm.responseData).code==1" size="mini">登录成功</el-tag>
          <el-tag type="error" v-if="ruleForm.status==0&&JSON.parse(ruleForm.responseData).code==0" size="mini">登录失败</el-tag>
          <el-tag type="error" v-if="ruleForm.status==1" size="mini">异常</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="登录账号：">{{ JSON.parse(ruleForm.requestParam).username }}</el-descriptions-item>
        <el-descriptions-item label="登录名：">
          {{ JSON.parse(ruleForm.responseData).code == 1 ? JSON.parse(ruleForm.responseData).data.user.name : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="登录IP：">{{ ruleForm.ip }}</el-descriptions-item>
        <el-descriptions-item label="登录地点：">{{ ruleForm.ipLocation }}</el-descriptions-item>
        <el-descriptions-item span="2" label="失败原因：" v-if="JSON.parse(ruleForm.responseData).code!=1">{{ JSON.parse(ruleForm.responseData).msg }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

  </el-dialog>
</template>

<script>
import operationLogApi from "@/api/system/operationLog";

export default {
  data() {
    return {
      queryForm: {
        id: "",
        opt: "",
        operatorId: "",
        operator: "",
        title: "",
        businessType: "",
        method: "",
        requestMethod: "",
        url: "",
        ip: "",
        ipLocation: "",
        requestParam: "",
        responseData: "",
        errorMsg: "",
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      dialogTitle: "",
      dialogVisible: false,
      ruleForm: {},
    };
  },
  mounted() {
    this.queryOperationLog();
  },
  methods: {
    clearQueryInput() {
      this.queryOperationLog();
    },
    submit() {
      this.queryForm.curPage = 1;
      this.queryOperationLog();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryOperationLog();
    },
    handleCurrentChange(val) {
      this.queryForm.curPage = val;
      this.queryOperationLog();
    },
    queryOperationLog() {
      operationLogApi.queryOperationLog(this.queryForm)
          .then((response) => {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          });
    },
    detail(index, row) {
      console.log(index, row);
      this.dialogTitle = "日志详情";
      this.dialogVisible = true;
      this.ruleForm = row;
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
    },
  },
};
</script>

<style scoped>

</style>